<template>
  <div class="text-center" v-if="resolutionScreen < 500">
    <v-snackbar v-model="shower" :timeout="timeout" :top="resolutionScreen < 500" :max-height="resolutionScreen">
      <v-card :color="color.theme" class="pa-0">
        <v-card-text class="pa-0">
      <v-list color="transparent" dark >
        <v-list-item  dense  v-for="(item, i) in list" :key="i">
            <v-list-item-content v-if="item.value === 100" >
            {{ $t("myinboxPage.dowload.dowloadsuccess") }}
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>  
          <v-list-item-content v-else>
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content> 
         
          <v-list-item-action>        
          <v-icon v-if="item.value === 100" color="#67E76C">mdi-check-bold</v-icon>
          <div v-else class="text-center pt-1"><a style="color:white;">{{item.value + '%'}}</a></div>   
          </v-list-item-action>    
        </v-list-item>
      
      </v-list>
      </v-card-text>
        <v-progress-linear v-if="btsdownload === true" indeterminate color="green" height="8"></v-progress-linear>
      </v-card>
    </v-snackbar>
  </div>
  <div class="text-center" v-else>
    <v-snackbar v-model="shower" :timeout="timeout" bottom class="v-snack__content">
      <v-card :color="color.theme" class="pa-0">
      <v-list color="transparent" dark >
        <v-list-item dense style="height:40px; width:400px; color:black;" v-for="(item, i) in list" :key="i">
          <v-list-item-content>
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>   
          <v-list-item-action>       
          <v-icon v-if="item.value === 100" color="#67E76C">mdi-check-bold</v-icon>
          <div v-else-if="item.value === 0" class="text-center pt-1"><a style="color:white;">กำลังรวบรวมไฟล์</a></div> 
          <div v-else class="text-center pt-1"><a style="color:white;">{{item.value + '%'}}</a></div>    
          </v-list-item-action>   
        </v-list-item>
      </v-list>
      </v-card>
        <v-progress-linear v-if="btsdownload === true" indeterminate color="green" height="8"></v-progress-linear>
    </v-snackbar>
  </div>

</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dialogLoginssoVue from "../optional/dialog-loginsso.vue";
export default {
  data: function() {
    return {
      processdelete:false,
      // snackbar:'',
      // timeout:1,
      namefdowload: "",
      downloadpercen: [],
      listdatadowload: [],
      newlist: [],
      progresspercent: "",
      dataIndex: "",
      datetime: "",
      timeoutdowload: 0,
      datadowload: {},
      listdatadowload: [],
      listdowload: [],
      timeout: 100000000000000,
      percentCompleted: 0,
      namedowload: "",
      Reference: "",
    };
  },
  comments: {},
  props: ["show", "filedata", "percen", "name", "list","btsdownload"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.downloadpercen = this.percen;
          this.namefdowload = this.name;
          console.log("list", this.list);
          console.log("this.btsdownload",this.btsdownload);
          // console.log("timeout",this.timeout);
        }
        return this.show;
      },
    },
  },
  methods: {

  },
};
</script>
<style></style>